/* eslint-disable no-restricted-globals  */
/* eslint-disable no-unused-vars  */
/* eslint-disable prefer-regex-literals  */
/* eslint-disable operator-linebreak  */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable indent */

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from './contexts/ConfigContext';

import './index.scss';
import App from './App';
// import { register as registerServiceWorker, unregister } from './serviceWorkerRegistration';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { store, persister } from './store';

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider>
            <PersistGate loading={null} persistor={persister}>
                <App />
            </PersistGate>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root'),
);

// Enable service worker registration only in production mode
// console.log('process.env.NODE_ENV', process.env.NODE_ENV);
// if (process.env.NODE_ENV === 'development') {
//     registerServiceWorker({
//         onUpdate: (registration) => {
//             const waitingServiceWorker = registration.waiting;
//             if (waitingServiceWorker) {
//                 waitingServiceWorker.addEventListener('statechange', (event) => {
//                     if (event.target.state === 'activated') {
//                         if (window.confirm('Uma nova versão está disponível, vamos atualizar agora?')) {
//                             waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
//                             waitingServiceWorker.addEventListener('statechange', (event) => {
//                                 if (event.target.state === 'activated') {
//                                     window.location.reload();
//                                 }
//                             });
//                         }
//                     }
//                 });
//             }
//         },
//     });
// } else {
//     unregister();
// }
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
