import React from 'react';
import Progress from './Progress';

const Loader = () => (
    <>
        <Progress isAnimating />
    </>
);

export default Loader;
