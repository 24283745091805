import React from 'react';

const PermissionDeniedLayout = () => (
    <div style={{
        width: '100%', height: '100%', textAlign: 'center', paddingTop: 100,
    }}
    >
        <h1>Permissão negada :(</h1>
    </div>
);

export default PermissionDeniedLayout;
