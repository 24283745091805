export const WHATSAPP_ACCOUNTS = {
    READ: ['whatsapp_accounts', 'can_read'],
    CREATE: ['whatsapp_accounts', 'can_create'],
    UPDATE: ['whatsapp_accounts', 'can_update'],
    DELETE: ['whatsapp_accounts', 'can_delete'],
};

export const TELEGRAM_ACCOUNTS = {
    READ: ['telegram_accounts', 'can_read'],
    CREATE: ['telegram_accounts', 'can_create'],
    UPDATE: ['telegram_accounts', 'can_update'],
    DELETE: ['telegram_accounts', 'can_delete'],
};

export const YOUTUBE_ACCOUNTS = {
    READ: ['youtube_accounts', 'can_read'],
    CREATE: ['youtube_accounts', 'can_create'],
    UPDATE: ['youtube_accounts', 'can_update'],
    DELETE: ['youtube_accounts', 'can_delete'],
};

export const WEBCHAT_ACCOUNTS = {
    READ: ['webchat_accounts', 'can_read'],
    CREATE: ['webchat_accounts', 'can_create'],
    UPDATE: ['webchat_accounts', 'can_update'],
    DELETE: ['webchat_accounts', 'can_delete'],
};

export const PROJECT = {
    READ: ['project', 'can_read'],
    CREATE: ['project', 'can_create'],
    UPDATE: ['project', 'can_update'],
    DELETE: ['project', 'can_delete'],
};

export const MONITORINGS = {
    READ: ['monitorings', 'can_read'],
    CREATE: ['monitorings', 'can_create'],
    UPDATE: ['monitorings', 'can_update'],
    DELETE: ['monitorings', 'can_delete'],
};

export const RASA_INTEGRATIONS = {
    READ: ['rasa_integrations', 'can_read'],
    CREATE: ['rasa_integrations', 'can_create'],
    UPDATE: ['rasa_integrations', 'can_update'],
    DELETE: ['rasa_integrations', 'can_delete'],
};

export const ATTENDANCES = {
    READ: ['attendances', 'can_read'],
    CREATE: ['attendances', 'can_create'],
    UPDATE: ['attendances', 'can_update'],
    DELETE: ['attendances', 'can_delete'],
};

export const ATTENDANCES_DEPARTMENTS = {
    READ: ['attendance_departments', 'can_read'],
    CREATE: ['attendance_departments', 'can_create'],
    UPDATE: ['attendance_departments', 'can_update'],
    DELETE: ['attendance_departments', 'can_delete'],
};

export const ATTENDANCES_AGENTS = {
    READ: ['attendance_agents', 'can_read'],
    CREATE: ['attendance_agents', 'can_create'],
    UPDATE: ['attendance_agents', 'can_update'],
    DELETE: ['attendance_agents', 'can_delete'],
};

export const ATTENDANCES_ROOMS = {
    READ: ['attendance_rooms', 'can_read'],
    CREATE: ['attendance_rooms', 'can_create'],
    UPDATE: ['attendance_rooms', 'can_update'],
    DELETE: ['attendance_rooms', 'can_delete'],
};

export const ATTENDANCES_SURVEYS = {
    READ: ['attendance_surveys', 'can_read'],
    CREATE: ['attendance_surveys', 'can_create'],
    UPDATE: ['attendance_surveys', 'can_update'],
    DELETE: ['attendance_surveys', 'can_delete'],
};

export const CONTACT_LISTS = {
    READ: ['contact_lists', 'can_read'],
    CREATE: ['contact_lists', 'can_create'],
    UPDATE: ['contact_lists', 'can_update'],
    DELETE: ['contact_lists', 'can_delete'],
};

export const CONTACTS = {
    READ: ['contacts', 'can_read'],
    CREATE: ['contacts', 'can_create'],
    UPDATE: ['contacts', 'can_update'],
    DELETE: ['contacts', 'can_delete'],
};

export const MESSAGE_CAMPAIGNS = {
    READ: ['message_campaigns', 'can_read'],
    CREATE: ['message_campaigns', 'can_create'],
    UPDATE: ['message_campaigns', 'can_update'],
    DELETE: ['message_campaigns', 'can_delete'],
};

export const OWN_ORGANIZATION = {
    READ: ['own_organization', 'can_read'],
    CREATE: ['own_organization', 'can_create'],
    UPDATE: ['own_organization', 'can_update'],
    DELETE: ['own_organization', 'can_delete'],
};

export const OWN_USER = {
    READ: ['own_user', 'can_read'],
    CREATE: ['own_user', 'can_create'],
    UPDATE: ['own_user', 'can_update'],
    DELETE: ['own_user', 'can_delete'],
};

export const ORG_USERS = {
    READ: ['org_users', 'can_read'],
    CREATE: ['org_users', 'can_create'],
    UPDATE: ['org_users', 'can_update'],
    DELETE: ['org_users', 'can_delete'],
};

export const USERS = {
    READ: ['users', 'can_read'],
    CREATE: ['users', 'can_create'],
    UPDATE: ['users', 'can_update'],
    DELETE: ['users', 'can_delete'],
};

export const MAIN_SETTINGS = {
    READ: ['main_settings', 'can_read'],
    CREATE: ['main_settings', 'can_create'],
    UPDATE: ['main_settings', 'can_update'],
    DELETE: ['main_settings', 'can_delete'],
};

export const VISITOR_METADATA = {
    READ: ['visitor_metadata', 'can_read'],
    CREATE: ['visitor_metadata', 'can_create'],
    UPDATE: ['visitor_metadata', 'can_update'],
    DELETE: ['visitor_metadata', 'can_delete'],
};

export const LDAP = {
    READ: ['ldap', 'can_read'],
    CREATE: ['ldap', 'can_create'],
    UPDATE: ['ldap', 'can_update'],
    DELETE: ['ldap', 'can_delete'],
};

export const AUDITING = {
    READ: ['auditing', 'can_read'],
    CREATE: ['auditing', 'can_create'],
    UPDATE: ['auditing', 'can_update'],
    DELETE: ['auditing', 'can_delete'],
};

export const ROLE_PERMISSIONS = {
    READ: ['role_permissions', 'can_read'],
    CREATE: ['role_permissions', 'can_create'],
    UPDATE: ['role_permissions', 'can_update'],
    DELETE: ['role_permissions', 'can_delete'],
};

export const REPORTS = {
    READ: ['reports', 'can_read'],
    CREATE: ['reports', 'can_create'],
    UPDATE: ['reports', 'can_update'],
    DELETE: ['reports', 'can_delete'],
};
