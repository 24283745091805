import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NavItem from '../NavItem';

const NavDynamic = ({ layout, item, projId }) => {
    const [navItems, setNavitems] = useState([]);
    const menuItems = [];

    useEffect(() => {
        let isMounted = true;
        const getData = async () => {
            if (item.src.includes(':projId')) {
                const src = item.src.replace(':projId', projId);
                item.src = src;
            }
            try{
                const response = await axios.get(`/${item.src}`);
                if (response.status === 200 && response.data.length > 0) {
                    for (const data_item of response.data) {
                        menuItems.push({
                            id: `dynamyc-${data_item._id}`,
                            type: 'item',
                            title: data_item.name,
                            icon: item.icon,
                            url: `${item.baseUrl}/${data_item._id}`,
                            breadcumps: false,
                        });
                    }
    
                }
                if (isMounted) {
                    setNavitems(Object.keys(menuItems).map((nav_item) => {
                        nav_item = menuItems[nav_item];
                        return <NavItem item={nav_item} layout={layout} key={nav_item.id} projId={projId} />;
                    }));
                } else {
                    console.log("navDynamic is not mounted");
                }
            } catch(error) {
                console.error(error)
            }
        };
        getData();
        return (() => isMounted = false)
    }, []);

    return (
        <>
            {navItems}
        </>
    );
};

export default NavDynamic;
