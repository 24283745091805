/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';

import ChatList from './ChatList';
import { LOGOUT } from '../../../../store/actions';
import { ConfigContext } from '../../../../contexts/ConfigContext';

import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import useHasPermission from '../../../../hooks/useHasPermission';
import * as permissions from '../../../../config/permissions';

const NavRight = () => {
    const dispatcher = useDispatch();
    const configContext = useContext(ConfigContext);
    const { rtlLayout } = configContext.state;

    const [listOpen, setListOpen] = useState(false);

    const loggedUser = useSelector((state) => state.account);
    const orgId = loggedUser.user.org;
    const [data, setData] = useState({
        username: '',
    });

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            try{
                const response = await axios.get('/auth/', {
                    disableAuthErrorHandling: true,
                });
                if (response?.status === 401) {
                    dispatcher({ type: LOGOUT });
                }
                const authData = response.data;
                if (mounted) setData({ username: authData.username });
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
        return (() => mounted = false)
    }, []);

    const handleLogout = () => {
        axios.post('/auth/logout').catch((err) => {console.error(err)});
        dispatcher({ type: LOGOUT });
    };

    return (
        <>
            <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto" id="navbar-right">
                <ListGroup.Item as="li" bsPrefix=" ">
                    <Dropdown alignRight={!rtlLayout} className="drp-user">
                        <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
                            <i className="icon feather icon-settings" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                <img src={avatar1} className="img-radius" alt="User Profile" />
                                <span style={{ textTransform: 'capitalize' }}>{data.username}</span>
                                {/* <Link to="#" className="dud-logout" title="Logout" onClick={handleLogout}>
                                    <i className="feather icon-log-out" />
                                </Link> */}
                            </div>
                            <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                                {useHasPermission(permissions.OWN_ORGANIZATION.CREATE) && (
                                    <ListGroup.Item as="li" bsPrefix=" ">
                                        <Link to="/orgs" className="dropdown-item">
                                            <i className="feather icon-settings" />
                                            {' '}
                                            Organizações
                                        </Link>
                                    </ListGroup.Item>
                                )}
                                {useHasPermission(permissions.MAIN_SETTINGS.CREATE) && (
                                    <ListGroup.Item as="li" bsPrefix=" ">
                                        <Link to="/settings" className="dropdown-item">
                                            <i className="feather icon-settings" />
                                            {' '}
                                            Configurações
                                        </Link>
                                    </ListGroup.Item>
                                )}
                                {useHasPermission(permissions.ROLE_PERMISSIONS.CREATE) && (
                                    <ListGroup.Item as="li" bsPrefix=" ">
                                        <Link to="/roles" className="dropdown-item">
                                            <i className="feather icon-user-plus" />
                                            {' '}
                                            Papéis de Usuários
                                        </Link>
                                    </ListGroup.Item>
                                )}
                                {/* {useHasPermission(permissions.OWN_ORGANIZATION.CREATE) && (
                                    <ListGroup.Item as="li" bsPrefix=" ">
                                        <Link to={`/org/${orgId}/groups`} className="dropdown-item">
                                            <i className="feather icon-users" />
                                            {' '}
                                            Grupos da Organização
                                        </Link>
                                    </ListGroup.Item>
                                )} */}
                                {/* {useHasPermission(permissions.VISITOR_METADATA.CREATE) && (
                                    <ListGroup.Item as="li" bsPrefix=" ">
                                        <Link to="/visitor_metadata/upsert" className="dropdown-item">
                                            <i className="feather icon-box" />
                                            {' '}
                                            Metadados Visitantes
                                        </Link>
                                    </ListGroup.Item>
                                )} */}
                                {useHasPermission(permissions.AUDITING.READ) && (
                                    <ListGroup.Item as="li" bsPrefix=" ">
                                        <Link to="/logs/view" className="dropdown-item">
                                            <i className="feather icon-activity" />
                                            {' '}
                                            Auditoria
                                        </Link>
                                    </ListGroup.Item>
                                )}
                                <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item" onClick={handleLogout}>
                                        <i className="feather icon-log-out" />
                                        {' '}
                                        Sair
                                    </Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
        </>
    );
};

export default NavRight;
