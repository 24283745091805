import React from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { BASE_URL } from '../../config/constant';

const GuestGuard = ({ children }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn, homepage } = account;

    if (isLoggedIn) {
        // return <Redirect to={BASE_URL} />;
        return <Redirect to={homepage} />;
    }

    return <>{children}</>;
};

export default GuestGuard;
