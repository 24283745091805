import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NavItem from '../NavItem';

const NavDynamic = ({ layout, item, projId }) => {
    const [navItems, setNavitems] = useState([]);

    const getData = () => {
        if (item.src.includes(':projId')) {
            const src = item.src.replace(':projId', projId);
            item.src = src;
        }
        axios.get(`/${item.src}`).then((response) => {
            if (response.status === 200 && response.data.length > 0) {
                const menuItems = [];
                for (const data_item of response.data) {
                    menuItems.push({
                        id: `dynamyc-${data_item._id}`,
                        type: 'item',
                        title: data_item.name,
                        icon: item.icon,
                        url: `${item.baseUrl}/${data_item._id}`,
                        breadcumps: false,
                    });
                }
                setNavitems(Object.keys(menuItems).map((nav_item) => {
                    nav_item = menuItems[nav_item];
                    return <NavItem item={nav_item} layout={layout} key={nav_item.id} projId={projId} />;
                }));
            }
        }).catch(() => {});
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <>
            {navItems}
        </>
    );
};

export default NavDynamic;
