import { useSelector } from 'react-redux';

const useHasPermission = (permissions = []) => {
    const account = useSelector((state) => state.account);

    const userPermissions = account?.user?.perms ?? {};
    if (typeof permissions === 'string' || permissions === null) {
        throw Error('Invalid parameter for useHasPermission hook!');
    }

    if (permissions.length === 0) {
        return true;
    }

    if (!Array.isArray(permissions?.[0])) {
        permissions = [permissions];
    }

    return permissions.some(([section, operation]) => (
        Boolean(userPermissions?.[section] && userPermissions[section].includes(operation))
    ));
};

export const getNestedPermission = (nestedKey, item) => {
    if (item.permissions) {
        return item.permissions;
    }

    if (item?.[nestedKey]) {
        return item?.[nestedKey].reduce((permissions, nestedRoute) => {
            let nestedRoutePermission = nestedRoute.permissions;

            if (!nestedRoutePermission) {
                return permissions;
            }

            if (!Array.isArray(nestedRoutePermission?.[0])) {
                nestedRoutePermission = [nestedRoutePermission];
            }

            return [...permissions, ...nestedRoutePermission];
        }, []);
    }

    return [];
};

export default useHasPermission;
