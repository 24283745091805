import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import OneSignal from 'react-onesignal';
import axios from 'axios';
import routes, { renderRoutes } from './routes';
import { BASENAME, ONESIGNAL_APP_ID } from './config/constant';
import 'react-toastify/dist/ReactToastify.css';
import { InjectAxiosInterceptors } from './utils/axios';
import { ConfigContext } from './contexts/ConfigContext';
import * as actionType from './store/actions';

const App = () => {
    const { state, dispatch } = useContext(ConfigContext);
    window.OneSignalDeferred = window.OneSignalDeferred || [];
    const account = useSelector((state) => state.account);
    const [initialized, setInitialized] = useState(false);
    const clickEventListener = (event) => {
        console.log(`[onesignal] click event: ${event}`);
    };
    const notificationDismissedListener = (event) => {
        console.log(`[onesignal] dismissed event: ${event}`);
    };
    try {
        if (account.token) {
            if (!initialized) {
                window.OneSignalDeferred.push((OneSignal) => {
                    OneSignal.init({
                        appId: ONESIGNAL_APP_ID, // Replace with your OneSignal App ID
                        inFocusDisplaying: 'notification', // Display notifications even when the user is on your site
                        autoResubscribe: false,
                        serviceWorkerParam: '/onesignal',
                        serviceWorkerPath: '/OneSignalSDKWorker.js',
                        allowLocalhostAsSecureOrigin: true,
                    }).then(() => {
                        OneSignal.User.PushSubscription.optIn();
                        window.OneSignalDeferred.push(() => {
                            OneSignal.Notifications.addEventListener('click', clickEventListener);
                        });
                        window.OneSignalDeferred.push(() => {
                            OneSignal.Notifications.addEventListener('dismiss', notificationDismissedListener);
                        });
                        window.OneSignal.Notifications.requestPermission();
                        // OneSignal.Slidedown.promptPush({ force: true });
                    });
                });
                setInitialized(true);
            } else {
                OneSignal.User.PushSubscription.optIn();
                OneSignal.Notifications.addEventListener('click', clickEventListener);
                OneSignal.Notifications.requestPermission();
                // OneSignal.Slidedown.promptPush({ force: true });
            }
        }
    } catch (error) {
        console.error(error);
    }

    useEffect(() => {
        const layoutType = localStorage.getItem('layoutType');
        if (layoutType) {
            dispatch({ type: actionType.LAYOUT_TYPE, layoutType });
        }

        if (OneSignal.User.PushSubscription.optedIn) {
            console.log('OneSignal.User.PushSubscription.id', OneSignal.User.PushSubscription.id);
            axios.put(`auth/user/${account.user.id}/player/${OneSignal.User.PushSubscription.id}`)
                .then(() => console.debug('Push id updated'))
                .catch((error) => console.error(error));
        } else {
            console.log('not optedin', OneSignal.User.PushSubscription.optedIn);
            OneSignal.User.PushSubscription.optIn();
            window.OneSignalDeferred.push(() => {
                OneSignal.Notifications.addEventListener('click', clickEventListener);
            });
            window.OneSignalDeferred.push(() => {
                OneSignal.Notifications.addEventListener('dismiss', notificationDismissedListener);
            });
            OneSignal.Notifications.requestPermission();
            // OneSignal.Slidedown.promptPush({ force: true });
        }
    }, []);

    useEffect(() => {
        if (state.layoutType === 'dark') {
            document.body.classList.add('dark-theme');
            document.body.classList.add('datta-dark');
        } else {
            document.body.classList.remove('dark-theme');
            document.body.classList.remove('datta-dark');
        }
    }, [state]);

    return (
        <>
            <Router basename={BASENAME}>
                <InjectAxiosInterceptors />
                {renderRoutes(routes)}
            </Router>
            <ToastContainer theme={state.layoutType === 'dark' ? 'dark' : 'light'} />
        </>
    );
};

export default App;
