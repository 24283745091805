import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from './actions';

export const initialState = {
    token: '',
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    homepage: '/home',
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
    case ACCOUNT_INITIALIZE: {
        const {
            isLoggedIn,
            user,
            token,
            homepage,
        } = action.payload;
        return {
            ...state,
            isLoggedIn,
            isInitialized: true,
            token,
            user,
            homepage,
        };
    }
    case LOGIN: {
        const { user } = action.payload;
        return {
            ...state,
            isLoggedIn: true,
            user,
        };
    }
    case LOGOUT: {
        return {
            ...state,
            isLoggedIn: false,
            token: '',
            user: null,
        };
    }
    default: {
        return { ...state };
    }
    }
};

export default accountReducer;
